<template>
  <!-- Error page-->
  <div>
    <nav-bar />
    <div class="misc-inner p-2 p-sm-3">
      <div class="misc-wrapper1 container">
        <div class=" text-justify1">
          <h2 class="main-title w-100 text-center">
            Delete My Zircly Account
          </h2>
          <br />
          <br />

          <p>
            To delete your Zircly account, follow these step-by-step procedures for both the website and mobile app, specifically in public community mode:

          </p>
          <h2>Deleting Your Zircly Account on the Website:</h2>

    <ol>
        <li><strong>Login to Zircly.</strong></li>
        <li>
            <strong>Navigate to the Profile Page:</strong>
            <ul>
                <li>Click on the avatar icon in the top right corner.</li>
                <li>Choose the option that redirects you to your profile page.</li>
            </ul>
        </li>
        <li>
            <strong>Access Account Settings:</strong>
            <ul>
                <li>Click on the "Edit" button on your profile page.</li>
            </ul>
        </li>
        <li>
            <strong>Initiate Deletion:</strong>
            <ul>
                <li>Look for the "Delete Account" option in the tab title and click on it.</li>
            </ul>
        </li>
        <li>
            <strong>Confirm Deletion:</strong>
            <ul>
                <li>A prompt will appear, warning that this action is irreversible.</li>
                <li>Click "Yes" to confirm, and your account will be marked as deleted in the database, logging you out.</li>
            </ul>
        </li>
    </ol>

    <h2>Deleting Your Zircly Account on the Mobile App:</h2>

    <ol>
        <li><strong>Login to the Zircly Mobile App.</strong></li>
        <li>
            <strong>Access the Profile Menu:</strong>
            <ul>
                <li>Click on the top right profile menu icon.</li>
            </ul>
        </li>
        <li>
            <strong>Initiate Deletion:</strong>
            <ul>
                <li>Look for the option that says "Delete My Account."</li>
            </ul>
        </li>
        <li>
            <strong>Confirm Deletion:</strong>
            <ul>
                <li>A prompt will appear, cautioning that this action is not reversible.</li>
                <li>Click "Yes" to confirm, and your account will be marked as deleted in the database, logging you out.</li>
            </ul>
        </li>
    </ol>

    <p>Please note that if you are part of a Zircly organization community, you cannot delete your account directly. In such cases, contact your company admin for account management. The admin will handle the account deletion process.</p>

        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";

const NavBar = () => import("@/components/NavBar.vue");

export default {
  components: {
    BLink,
    BButton,
    BImg,
    NavBar,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
      InterviewZoom:require("@/assets/images/zoom_doc/zoomfromInterview.png"),
      ManageZoomLogIn:require("@/assets/images/zoom_doc/loginZoomFromProfile.png"),
      ManageZoomLogOut:require("@/assets/images/zoom_doc/LogOutZoomfromProfile.png"),
    };
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}
</style>
